<template>
  <div class="row">
    <b-modal id="display-history-invoice-modal" ref="display-history-invoice-modal" hide-header hide-header-close
      scrollable size="lg">
      <div class="row mx-0 py-7">
        <div class="col-12  mx-0 py-2 text-xl">
          <div class="col-12 py-2 px-0 text-center text-xl font-weight-boldest navy-blue-border-bottom-1px bg-fine">
            {{$t('invoice.invoice_history')}}
          </div>
          <template>
            <div class="col-12 d-flex px-0 justify-content-center">
              <div
                class="w-50px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px navy-blue-border-left-1px">
                #
              </div>
              <div
                class="w-200px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px">
                {{ $t('following.date') }}
              </div>
              <div
                class="w-200px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px">
                {{$t('invoice.person_performing_the_transaction')}}
              </div>
              <div
                class="w-200px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px">
                {{$t('invoice.prev_status')}}
              </div>
              <div
                class="w-200px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px">
                {{$t('invoice.current_status')}}
              </div>
            </div>
            <div v-if="form.invoice_history && form.invoice_history.length > 0">
              <div v-for="(history, key) in form.invoice_history" :key="key"
                class="col-12 d-flex px-0 justify-content-center navy-blue-border-bottom-1px">
                <div
                  class="w-50px py-1 text-center font-weight-bold navy-blue-border-right-1px navy-blue-border-left-1px">
                  {{ key + 1 }}
                </div>
                <div class="w-200px py-1 text-center font-weight-bold navy-blue-border-right-1px">
                  {{ history.created_at }}
                </div>
                <div class="w-200px py-1 text-center font-weight-bold navy-blue-border-right-1px">
                  {{ history.user.name }}
                </div>
                <div class="w-200px py-1 text-center font-weight-bold navy-blue-border-right-1px">
                  {{ history.invoice_prev_status.translation.name }}
                </div>
                <div class="w-200px py-1 text-center font-weight-bold navy-blue-border-right-1px">
                  {{ history.invoice_last_status.translation.name }}
                </div>
              </div>
            </div>
            <div v-else class="col-12 d-flex px-0 justify-content-center">
              Fatura Geçmişi Bulunamadı
            </div>
          </template>
        </div>
      </div>
      <template #modal-footer>
        <div class="col-12 text-center">
          <button class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeModal('display-history-invoice-modal')">
            {{ $t('general.close') }}
          </button>
        </div>
      </template>
    </b-modal>
    <div id="filter-card" class="row justify-content-start items-baseline w-100 mb-2" style="margin-bottom: -5px;">
        <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
          <i class="fa fa-chevron-left"></i>
        </span>
      <h3 id="page-title" class="d-inline-block">{{ $t('invoice.accepted_invoice') }}</h3>
    </div>

    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="px-5 py-3">
            <div class="row items-start">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-12 items-start">
                  <date-picker-input
                      :disabled="true"
                      :model="today"
                      :required="false"
                      :title="$t('invoice.date')"
                  ></date-picker-input>
                </div>

                <div class="col-12">
                  <custom-multi-select
                      :item-per-row="1"
                      :max="1"
                      :model.sync="form.supplier_company_id"
                      :not-list="true"
                      :options="options.companyOptions"
                      :required="true"
                      :title="$t('invoice.company_name')"
                      name="invoice_company_name"
                  ></custom-multi-select>
                </div>
                <div class="col-12">
                  <div> İrsaliye:</div>
                  {{ form.logo_waybills }}
                </div>

                <div class="col-12 row mx-0 px-1">
                  <div class="col-6 px-1">
                    <button-with-icon
                        :active="true"
                        :disabled="form.invoice_category_id !== 1"
                        :text="$t('invoice.scan_qr_code')"
                        icon-name="icons.invoice.by_scanning_qr"
                        size="sm"
                        @onClick="form.invoice_category_id = 1"
                    ></button-with-icon>
                  </div>
                  <div class="col-6 px-1">
                    <button-with-icon
                        :active="true"
                        :disabled="form.invoice_category_id !== 2"
                        :text="$t('invoice.enter_manually')"
                        icon-name="icons.invoice.manually_entry"
                        size="sm"
                        @onClick="form.invoice_category_id = 2"
                    ></button-with-icon>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-12">
                  <text-input
                      :disabled="isValidChangeInvoiceNumber"
                      :model.sync="form.invoice_no"
                      :placeholder="$t('invoice.please_enter_invoice_number')"
                      :required="true"
                      :title="$t('invoice.invoice_number')"
                  ></text-input>
                </div>
                <div class="col-12">
                  <date-picker-input
                      :disabled="true"
                      :model.sync="form.invoice_date"
                      :required="true"
                      :title="$t('invoice.invoice_date')"
                  ></date-picker-input>
                </div>

                <div class="col-12 d-flex justify-content-start">
                  <custom-multi-select
                      :disabled="true"
                      :is-inline="false"
                      :max="1"
                      :model.sync="form.invoice_type_id"
                      :not-list="true"
                      :options="options.invoiceTypeOptions"
                      :required="true"
                      :title="$t('invoice.invoice_type')"
                      class="mr-3"
                      name="invoice_type"
                  ></custom-multi-select>
                  <custom-multi-select
                      :disabled="true"
                      :is-inline="false"
                      :max="1"
                      :model.sync="form.invoice_category_id"
                      :not-list="true"
                      :options="options.invoiceCategoryOptions"
                      :required="true"
                      :title="`Tipi`"
                      class="mr-3"
                      name="invoice_kind"
                  ></custom-multi-select>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-12 mb-3 d-lg-flex d-md-none">
                  <div class="w-200px">
                    <span class="text-lg">
                      {{ $t('invoice.overhead_invoice') }}
                    </span>
                  </div>
                  <div class="flex-grow-1">
                    <b-form-checkbox
                        id="invoice_overhead_invoice"
                        v-model="form.overhead_invoice"
                        class="checkbox"
                        name="invoice_overhead_invoice"
                        unchecked-value="0"
                        value="1"
                    ></b-form-checkbox>
                  </div>
                </div>
                <div class="col-12 mb-3 d-lg-flex d-md-none">
                  <div class="w-200px">
                    <span class="text-lg">
                      {{ $t('general.description ') }}
                    </span>
                  </div>
                  <div class="flex-grow-1">
                    <b-form-checkbox
                        id="accounting_description"
                        v-model="accountingDescriptionChecked"
                        class="checkbox"
                        name="accounting_description"
                    ></b-form-checkbox>
                  </div>
                </div>

                <div class="col-12 mb-3 d-lg-flex d-md-none" v-if="accountingDescriptionChecked">
                  <textarea-input
                    :is-inline="false"
                    :helper-text="
                      $t('invoice.accounting_description')
                    "
                    :placeholder="
                      $t('invoice.accounting_description')
                    "
                    :required="false"
                    :model.sync="form.accounting_description"
                    :title=" $t('invoice.accounting_description')"
                    :is-valid="accountingDescriptionChecked "
                  ></textarea-input>
                </div>
                <div class="col-12 mb-3 d-lg-flex d-md-none" v-if="isUserGranted('Staff', ['invoiceCheck'], false)">
                    <custom-multi-select
                    :helperText="$t('general.please_select')"
                    :title="$t('scope.Role') + ':'"
                    :model.sync="role_id"
                    name="roles"
                    :options="roleOptions"
                    :not-list="false"
                    :is-inline="true"
                    :required="false"
                    :item-per-row="1"
                     @save="changeRole($event)"
                    class="font-weight-bolder"
                    input-max-width="100%"
                    input-width="100%"
                    width-class="100%"
                  ></custom-multi-select>
                </div>
                <div class="col-12 mb-3 d-lg-flex d-md-none" v-if="isUserGranted('Staff', ['invoiceCheck'], false)">
                    <custom-multi-select
                    :helperText="$t('general.please_select')"
                    :title="$t('general.user') + ':'"
                    :model.sync="user_id"
                    name="users"
                    :options="userOptions"
                    :not-list="true"
                    :is-inline="true"
                    :required="false"
                    :max="1"
                    :item-per-row="1"
                    @save="changeUser($event)"
                    class="font-weight-bolder"
                    input-max-width="100%"
                    input-width="100%"
                    width-class="100%"
                  ></custom-multi-select>
                </div>
                <div v-if="isValidToSubmit && isValidLogoId || form.overhead_invoice==1" class="col-12 d-flex justify-content-end my-3">
                  <button-with-icon
                      :icon-name="null"
                      :text="$t('invoice.save_invoice')"
                      class="w-200px text-primary"
                      size="lg"
                      @onClick="submitInvoice"
                  ></button-with-icon>
                </div>
              </div>
            </div>
            <div class="relative">
              <div class="col-12 row mx-0 font-weight-boldest pb-1 pt-1">
                <i class="fa fa-eye" role="button" @click="showHistoryInvoiceModal()">
                  Fatura geçmişi
                </i>
              </div>
              <div v-show="false" class="col-12 p-0 m-0 text-primary h-20px">
              <span class="float-right" @click="addNewRow" v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-35px h-35px d-inline-block object-fill cursor-pointer'
                })">
             </span>
              </div>

              <div v-show="isValidToContinue" class="w-100 col-12 border-15px navy-blue-border-1px mx-3 mt-5 mb-1">
                <table class="table w-80 border-15px mb-0">
                  <thead class="w-100">
                  <tr>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="text-right text-lg pr-10">
                        {{ $t('invoice.instruction_type') }}
                      </div>
                    </th>

                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="text-right text-lg pr-10">
                        {{ $t('invoice.order_number') }}
                      </div>
                    </th>

                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="text-right text-lg pr-10">
                        {{ $t('invoice.product') }}
                      </div>
                    </th>

                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="text-right text-lg pr-10">
                        {{ $t('invoice.code') }}
                      </div>
                    </th>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.code_name') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.amount') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.unit') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.unit_price') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.vat_amount') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.index_amount') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.amount_total') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>

                    <th class="navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('order.description') }}
                      </div>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="navy-blue-bottom-1px">
                  <template v-for="(item, key) in orderDetails">
                    <tr>
                      <td class="navy-blue-border-right-1px">
                        <custom-multi-select
                            :has-bottom-margin="false"
                            :is-inline="false"
                            :model.sync="item.instruction_type_id"
                            :options="options.instructionTypeOptions"
                            input-width="150px"
                            max="1"
                            name="wayBillKind"
                            not-list="true"
                            title=""
                            @save="instructionTypeChanged($event, key)"
                        />
                      </td>

                      <td v-if="orderDetails[key].hasOwnProperty('orderOptions') && orderDetails[key].orderOptions"
                          class="navy-blue-border-right-1px">
                        <custom-multi-select
                            :has-bottom-margin="false"
                            :is-inline="false"
                            :model.sync="item.waybillable_ids"
                            :options="item.orderOptions"
                            input-width="150px"
                            max="1"
                            name="waybillable_ids"
                            object-key="name"
                            title=""
                            :show-invalid="isValidSelectOverheadDisableWaybilleId"
                            @save="waybillableIdsChanged($event, key)"
                        />
                      </td>

                      <td class="navy-blue-border-right-1px">
                        <div v-if="item && item.hasOwnProperty('waybillable') && item.waybillable"
                             class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                          <custom-multi-select
                              :is-inline="false"
                              :model.sync="item.product_color_id"
                              :options="item.waybillable.detail"
                              :placeholder="$t('waybill.please_enter_product_color')"
                              max="1"
                              name="wayBillOrderColor"
                              not-list="true"
                              object-key="color"
                              title=""
                              @save="onSelectProduct($event, key)"
                          >
                          </custom-multi-select>
                        </div>
                      </td>

                      <td class="navy-blue-border-right-1px">
                        <input v-model="item.line_code" :disabled="true" class="form-control d-inline-block mb-5"
                               type="text">
                      </td>

                      <td class="navy-blue-border-right-1px items-start">
                        <input v-model="item.line_code_name" :disabled="true" class="form-control d-inline-block mb-5"
                               type="text">
                      </td>

                      <td class="navy-blue-border-right-1px items-start">
                        <input v-model="item.amount" class="form-control d-inline-block mb-5"
                               type="number">
                      </td>

                      <td class="navy-blue-border-right-1px items-start">
                        <custom-multi-select
                            :disabled="true"
                            :input-width="`65px`"
                            :is-inline="false"
                            :item-per-row="1"
                            :max="1"
                            :model.sync="item.amount_unit_id"
                            :not-list="true"
                            :options="options.unitOptions"
                            :required="false"
                            :title="null"
                            name=""
                        ></custom-multi-select>
                      </td>
                      <td class="navy-blue-border-right-1px">
                        <input v-model="item.unit_price" class="form-control form-control-sm d-inline-block"
                               step="1" type="number">
                      </td>
                      <td class="navy-blue-border-right-1px">
                        <custom-multi-select
                            :max="1"
                            :model.sync="item.vat_rate_id"
                            :not-list="true"
                            :options="vatRateOptions"
                            :required="false"
                            name="transaction_vat_rate_id"
                            title=""
                            :disabled="true"
                        ></custom-multi-select>
                        {{ item.vat_price | priceFormat }} {{ invoiceCurrency }}
                      </td>
                      <td class="navy-blue-border-right-1px">
                        {{ item.price | priceFormat }} {{ invoiceCurrency }}
                      </td>
                      <td class="navy-blue-border-right-1px">
                        {{ item.total_price | priceFormat }} {{ invoiceCurrency }}
                      </td>
                      <td class="">
                        <input v-model="item.description" class="form-control form-control-sm" type="text">
                        <span></span>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>

              
              <div
                  class="float-right mb-n20 w-50 lg:w-35 md:w-50 sm:w-50 py-3 px-5 border-15px navy-blue-border-1px bg-white"
                  style="bottom: 0; right: 0;">
                <invoice-layout
                    :buying-type-options="[]"
                    :buying_type_id="form.invoice_receiving_type_id"
                    :currency-options="options.currencyOptions"
                    :currency-rates="currencyRates"
                    :currency_unit_id.sync="form.currency_unit_id"
                    :discount.sync="form.total_discount"
                    :exchange.sync="form.exchange_rate"
                    :item="form"
                    :read-only="true"
                    :transactions="orderDetails"
                    :vat-options="vatRateOptions"
                    :vat-rates="vat_rates"
                ></invoice-layout>
              </div>


              <div v-for="(item, index) in form.description" class="col-6 py-2" :key="index">
                <div class="col-4">
                  <p class="font-size-lg font-weight-bold">{{ $t('waybill.general_description') }} {{ ++index }} : </p>
                </div>
                <div class="col-8">
                  <p class="font-size-lg">{{ item ?? '-' }}</p>
                </div>
              </div>
            </div>
            <div class="col-12 row my-2">
              <div class="col-12 d-flex justify-content-center">
                <button-with-icon
                    :icon-name="null"
                    :text="$t('invoice.create_invoice_reflection').toUpperCase()"
                    class="mx-1 font-weight-boldest"
                    size="lg"
                    @onClick="createReflectionInvoice=!createReflectionInvoice"
                ></button-with-icon>
                <button-with-icon
                      :icon-name="null"
                      :text="$t('general.give_up').toUpperCase()"
                      class="mx-1 font-weight-boldest"
                      size="md"
                      @onClick="giveUpReflectionInvoices()"
                  ></button-with-icon>
              </div>
            </div>
            <!-- yansıtma faturası opsiyonları -->
            <div class="row my-2 d-flex justify-content-center" v-if="createReflectionInvoice">
              <div class="col-3">
                  <custom-multi-select
                      :item-per-row="1"
                      :max="1"
                      :model.sync="reflectionInvoiceSuplierCompanySelected"
                      :not-list="true"
                      :options="options.companyOptions"
                      :required="true"
                      :title="$t('invoice.company_name')"
                      name="invoice_company_name"
                  ></custom-multi-select>
                </div>
              <div class="col-3">
                  <custom-multi-select
                      :item-per-row="1"
                      :max="1"
                      :model.sync="invoiceReflectTypeSelected"
                      :not-list="true"
                      :options="invoiceReflectTypeOptions"
                      :title="$t('general.selection_type')"
                      name="invoice_company_name"
                  ></custom-multi-select>
              </div>
              <div class="col-3" v-if="invoiceReflectTypeSelected==2">
                  <custom-multi-select
                      :item-per-row="1"
                      :model.sync="reflectInvoiceSelectedRows"
                      :not-list="false"
                      :options="reflectInvoiceRowsOptions"
                      :required="invoiceReflectTypeSelected!=null"
                      :title="$t('general.lines')"
                      name="invoice_company_name"
                  ></custom-multi-select>
              </div>
              <div class="col-12 row">
                <div class="col-12 d-flex justify-content-center">
                  <button-with-icon
                      :icon-name="null"
                      :text="$t('general.save').toUpperCase()"
                      class="mx-1 font-weight-boldest"
                      size="md"
                      v-if="checkNewReflectInvoiceLine"
                      @onClick="createReflectionInvoices()"
                  ></button-with-icon>
                  
                </div>
              </div>
            </div>
            <!-- yansıtma faturası opsiyonları bitiş -->
            <b-collapse v-if="reflectionInvoice.length" id="reflect_invoices" class="pt-1" visible>
              <div v-for="(reflectItem, index) in reflectionInvoice"  v-show="isValidToContinue" v-if="reflectionInvoice.length" :key="index" class="row col-12 mt-2">
                <h4 v-b-toggle="`reflect_invoice_detail_${index + 1}`"
                    class="d-inline-block cursor-pointer mt-3 text-primary bottom-border-1px-navy font-weight-boldest"
                    variant="info">
                  <span class="d-inline-block pr-2">{{ index + 1 }}. {{ $t('general.reflectInvoice') }}</span>
                  <i class="fas fa-caret-down text-navy-blue opacity-75"></i>
                </h4>
                  <span v-if="reflectionInvoice.length>0" class="cursor-pointer ml-5" @click="deleteReflectInvoicesItem(index)" v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-25px h-25px d-inline-block mr-3 '
                      })"></span>

                      
              <br>

              <b-collapse :id="`reflect_invoice_detail_${index + 1}`" class="mb-5" visible>
                <div class="row col-12 mt-5">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">

                  <div class="col-12">
                    <custom-multi-select
                        :item-per-row="1"
                        :max="1"
                        :model.sync="reflectItem.supplier_company_id"
                        :not-list="true"
                        :options="options.companyOptions"
                        :required="true"
                        :title="$t('invoice.company_name')"
                        name="invoice_company_name"
                    ></custom-multi-select>
                  </div>
                  <div class="col-12 row mx-0 px-1">
                    <div class="col-6 px-1">
                      <button-with-icon
                          :active="true"
                          :disabled="reflectItem.invoice_category_id !== 1"
                          :text="$t('invoice.scan_qr_code')"
                          icon-name="icons.invoice.by_scanning_qr"
                          size="sm"
                          @onClick="reflectItem.invoice_category_id = 1"
                      ></button-with-icon>
                    </div>
                    <div class="col-6 px-1">
                      <button-with-icon
                          :active="true"
                          :disabled="reflectItem.invoice_category_id !== 2"
                          :text="$t('invoice.enter_manually')"
                          icon-name="icons.invoice.manually_entry"
                          size="sm"
                          @onClick="reflectItem.invoice_category_id = 2"
                      ></button-with-icon>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                  <div class="col-12 d-flex justify-content-start">
                    <custom-multi-select
                        :disabled="true"
                        :is-inline="false"
                        :max="1"
                        :model.sync="reflectItem.invoice_type_id"
                        :not-list="true"
                        :options="options.invoiceTypeOptions"
                        :required="true"
                        :title="$t('invoice.invoice_type')"
                        class="mr-3"
                        name="invoice_type"
                    ></custom-multi-select>
                    <custom-multi-select
                        :disabled="true"
                        :is-inline="false"
                        :max="1"
                        :model.sync="reflectItem.invoice_category_id"
                        :not-list="true"
                        :options="options.invoiceCategoryOptions"
                        :required="true"
                        :title="`Tipi`"
                        class="mr-3"
                        name="invoice_kind"
                    ></custom-multi-select>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                  <div class="col-12 mb-3 d-lg-flex d-md-none">
                    <div class="w-200px">
                      <span class="text-lg">
                        {{ $t('general.description ') }}
                      </span>
                    </div>
             
                    <div class="flex-grow-1">
                      <b-form-checkbox
                          :id="'accounting_reflect_description_'+index"
                          v-model="reflectItem.accounting_description_checked"
                          class="checkbox"
                          :name="'accounting_reflect_description_'+index"
                      ></b-form-checkbox>
                    </div>
                  </div>

                  <div class="col-12 mb-3 d-lg-flex d-md-none" v-if="reflectItem.accounting_description_checked">
                    <textarea-input
                      :is-inline="false"
                      :helper-text="
                        $t('invoice.accounting_description')
                      "
                      :placeholder="
                        $t('invoice.accounting_description')
                      "
                      :required="false"
                      :model.sync="reflectItem.accounting_description"
                      :title=" $t('invoice.accounting_description')"
                      :is-valid="reflectItem.accounting_description_checked"
                    ></textarea-input>
                  </div>
                </div>
                </div>

                <div class="w-100 row col-12 border-15px navy-blue-border-1px mx-3 mt-5 mb-1">
                  <table class="table w-80 border-15px mb-0">
                    <thead class="w-100">
                    <tr>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="text-right text-lg pr-10">
                          {{ $t('invoice.instruction_type') }}
                        </div>
                      </th>

                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="text-right text-lg pr-10">
                          {{ $t('invoice.order_number') }}
                        </div>
                      </th>

                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="text-right text-lg pr-10">
                          {{ $t('invoice.product') }}
                        </div>
                      </th>

                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="text-right text-lg pr-10">
                          {{ $t('invoice.code') }}
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('invoice.code_name') }}
                          <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('invoice.amount') }}
                          <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('invoice.unit') }}
                          <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('invoice.unit_price') }}
                          <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('invoice.vat_amount') }}
                          <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('invoice.index_amount') }}
                          <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('invoice.amount_total') }}
                          <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>

                      <th class="navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('order.description') }}
                        </div>
                      </th>
                    </tr>
                    </thead>
                    <tbody class="navy-blue-bottom-1px">
                    <template v-for="(item, key) in reflectItem.transactions">
                      <tr>
                        <td class="navy-blue-border-right-1px">
                          <custom-multi-select
                              :has-bottom-margin="false"
                              :is-inline="false"
                              :model.sync="item.instruction_type_id"
                              :options="options.instructionTypeOptions"
                              input-width="150px"
                              max="1"
                              name="wayBillKind"
                              not-list="true"
                              title=""
                              @save="getWaybillOrderOptionsForReflectInvoice($event, key)"
                          />
                        </td>

                        <td v-if="item.hasOwnProperty('orderOptions') && item.orderOptions"
                            class="navy-blue-border-right-1px">
                          <custom-multi-select
                              :has-bottom-margin="false"
                              :is-inline="false"
                              :model.sync="item.waybillable_ids"
                              :options="item.orderOptions"
                              input-width="150px"
                              max="1"
                              name="waybillable_ids"
                              object-key="name"
                              title=""
                              :show-invalid="isValidSelectOverheadDisableWaybilleId"
                              @save="waybillableIdsChangedForReflectInvoice($event, key)"
                          />
                        </td>

                        <td class="navy-blue-border-right-1px">
                          <div v-if="item && item.hasOwnProperty('waybillable') && item.waybillable"
                              class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                            <custom-multi-select
                                :is-inline="false"
                                :model.sync="item.product_color_id"
                                :options="item.waybillable.detail"
                                :placeholder="$t('waybill.please_enter_product_color')"
                                max="1"
                                name="wayBillOrderColor"
                                not-list="true"
                                object-key="color"
                                title=""
                                @save="onSelectProductForReflectInvoice($event, key)"
                            >
                            </custom-multi-select>
                          </div>
                        </td>

                        <td class="navy-blue-border-right-1px">
                          <input v-model="item.line_code" :disabled="true" class="form-control d-inline-block mb-5"
                                type="text">
                        </td>

                        <td class="navy-blue-border-right-1px items-start">
                          <input v-model="item.line_code_name" :disabled="true" class="form-control d-inline-block mb-5"
                                type="text">
                        </td>

                        <td class="navy-blue-border-right-1px items-start">
                          <input v-model="item.amount" class="form-control d-inline-block mb-5"
                                type="number">
                        </td>

                        <td class="navy-blue-border-right-1px items-start">
                          <custom-multi-select
                              :disabled="true"
                              :input-width="`65px`"
                              :is-inline="false"
                              :item-per-row="1"
                              :max="1"
                              :model.sync="item.amount_unit_id"
                              :not-list="true"
                              :options="options.unitOptions"
                              :required="false"
                              :title="null"
                              name=""
                          ></custom-multi-select>
                        </td>
                        <td class="navy-blue-border-right-1px">
                          <input v-model="item.unit_price" class="form-control form-control-sm d-inline-block"
                                step="1" type="number">
                        </td>
                        <td class="navy-blue-border-right-1px">
                          <custom-multi-select
                              :max="1"
                              :model.sync="item.vat_rate_id"
                              :not-list="true"
                              :options="vatRateOptions"
                              :required="false"
                              name="transaction_vat_rate_id"
                              title=""
                              :disabled="true"
                          ></custom-multi-select>
                          {{ item.vat_price | priceFormat }} {{ invoiceCurrency }}
                        </td>
                        <td class="navy-blue-border-right-1px">
                          {{ item.price | priceFormat }} {{ invoiceCurrency }}
                        </td>
                        <td class="navy-blue-border-right-1px">
                          {{ item.total_price | priceFormat }} {{ invoiceCurrency }}
                        </td>
                        <td class="">
                          <input v-model="item.description" class="form-control form-control-sm" type="text">
                          <span></span>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="12">
                            <b-form-checkbox
                              v-model="item.ignore_calculation"
                              unchecked-value="0"
                              value="1"
                              size="md"
                              switch>
                            {{ $t('waybill.ignore_calculation') }}
                          </b-form-checkbox>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
                <div
                    class="row float-right mb-n20 w-50 lg:w-35 md:w-50 sm:w-50 py-3 px-5 border-15px navy-blue-border-1px bg-white"
                    style="bottom: 0; right: 0;">
                  <invoice-layout
                      :buying-type-options="options.receiveTypeOptions"
                      :buying_type_id="form.invoice_receiving_type_id"
                      :currency-options="options.currencyOptions"
                      :currency-rates="currencyRates"
                      :currency_unit_id.sync="reflectItem.currency_unit_id"
                      :discount.sync="reflectItem.total_discount"
                      :exchange.sync="reflectItem.exchange_rate"
                      :item="reflectItem"
                      :read-only="true"
                      :transactions="reflectItem.transactions"
                      :vat-options="vatRateOptions"
                      :vat-rates="reflectItem.vat_rates"
                      :readOnly="false"
                  ></invoice-layout>
                </div>
              </b-collapse>
            </div> 
        </b-collapse>
            
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters, mapMutations} from 'vuex'
import moment from "moment";

import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import NumberInput from "@/assets/components/inputs/NumberInput";
import TextInput from "@/assets/components/inputs/TextInput";
import FileInput from "@/assets/components/inputs/FileInput";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import DataTable from "@/assets/components/dataTable/DataTable";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

import {
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  LOADING,
  RESET_VALUES,
  SET_LOADING,
  UPDATE_ITEM_BY_ID,
  CREATE_ITEM
} from "@/core/services/store/REST.module";
import {LARAVEL_DATE_FORMAT,MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import SelectInput from "@/assets/components/inputs/SelectInput";
import InvoiceLayout from "@/view/pages/invoice/layouts/InvoiceLayout";
import _ from "lodash";

export default {
  name: "InvoiceCreateEdit",
  components: {
    InvoiceLayout,
    SelectInput,
    FileInput,
    NumberInput,
    ButtonWithIcon,
    DatePickerInput,
    CustomMultiSelect,
    DashboardBox,
    DataTable,
    InfoTooltip,
    TextInput,
    TextareaInput
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    vat_rates() {
      let self = this;
      
      return _.map(_.groupBy(this.orderDetails, 'vat_rate_id'), (o, idx) => {
        let vatRate = self.options.vatOptions[idx]
        return {
          rate: vatRate, sum: _.sumBy(o, 'vat_price')
        }
      })
    },
    vat_rates_reflection() {
      this.reflectionInvoice.forEach(item => {
        item.vat_rates = _.map(_.groupBy(item.transactions, 'vat_rate_id'), (o, idx) => {
          let vatRate = this.options.vatOptions[idx];
          return {
            rate: vatRate,
            sum: _.sumBy(o, 'vat_price')
          };
        });
      });
    },
    vatRateOptions() {
      let options = this.options.vatOptionsRaw || {};
      let data = options.filter(item => +item.type_id === 4)
      return this.convertArrayToObjectByKey(data, 'id', 'vat.rate');
    },
    invoiceCurrency() {
      if (this.form.currency_unit_id) {
        return this.options.currencyOptions[this.form.currency_unit_id]
      }
      return "";
    },
    transactionVatPrice() {
      return function (key) {
        let self = this;
        return self.transactionPrice(key) * self.options.vatOptions[self.form.vat_rate_id] / 100;
      }
    },
    transactionPrice() {
      return function (key) {
        let self = this;
        return self.orderDetails[key].amount * self.orderDetails[key].unit_price;
      }
    },
    transactionTotalPrice() {
      return function (key) {
        return this.transactionPrice(key) + this.transactionVatPrice(key);
      }
    },
    calculated_vat() {
      let sum = 0;
      let self = this;
      this.orderDetails.forEach(item => {
        sum += item.vat_price;
      })
      return sum;
    },
    total_amount() {
      return this.total_price;
    },
    total() {
      let sum = 0;
      this.orderDetails.forEach(item => {
        sum += item.amount * item.unit_price;
      })
      return sum;
    },
    isValidSelectOverheadDisableWaybilleId(){
      if(Number(this.form.overhead_invoice)===1) return false;
      return true;
    },
    isValidToContinue() {
      if (Number(this.form.invoice_type_id) === 0 ) return false;
      if (Number(this.form.invoice_category_id) === 0) return false;
      if (Number(this.form.supplier_company_id) === 0) return false;
      if (!this.form.invoice_no&&this.form.overhead_invoice==0) return false;
      return true;
    },
    isValidLogoId(){
      if(!this.form.logo_id){
        return true; 
      }
      if(this.form.logo_id){
        return true;
      }
      return false;
    },
    isValidToSubmit() {
      let returnStatus = true;

      if (!this.isValidToContinue) {
        return returnStatus = false;
      }
      
      this.orderDetails.forEach((item) => {
        if (!item.instruction_type_id) {
          returnStatus = false;
        }

        if (!item.product_color_id) {
          returnStatus = false;
        }
      })
      return returnStatus;
    },
    isValidChangeInvoiceNumber(){
      if (this.isUserGranted('Invoice', ['changeInvoiceNumber'],false,true)) {
        return false;
      }
      return true
    },
    accountTheInvoiceTotal() {
      let total;
      return total = this.invoiceUnitAmount * this.invoiceAmount;
    },
    isValidToShowPopUp() {
      if (!this.orderDetails.length) return false;
      let returnValue = true;
      this.orderDetails.forEach(item => {
        if (!item.line_code) {
          returnValue = false;
          return false;
        }
        if (!item.line_code_name) {
          returnValue = false;
          return false;
        }
        if (!item.amount) {
          returnValue = false;
          return false;
        }
        if (!item.amount_unit_id) {
          returnValue = false;
          return false;
        }
        if (!item.unit_price) {
          returnValue = false;
          return false;
        }
      });
      if (!returnValue) return false;

      return true;
    },
    reflectInvoiceRowsOptions(){
      let options=[];
      this.orderDetails.forEach(function(item,index){
        let name=item.waybillable?item.waybillable.name:null;
        let productName=item.waybillable?item.waybillable.product_name:null;

        let fullName=index+1+'.Satır:'+name+' - '+productName+' Miktar: '+item.amount+' Birim Fiyat: '+item.unit_price;

        options.push({
          index:index,
          name:fullName
        })
      })
      options = this.convertArrayToObjectByKey(options, 'index','name');
      return options;
    },
    checkNewReflectInvoiceLine(){
      let status=true;
      if (this.reflectionInvoiceSuplierCompanySelected==null){
        status=true;
      }
     
      if( this.invoiceReflectTypeSelected==null){
        status=false;
      }

      if(this.invoiceReflectTypeSelected=='2' && this.reflectInvoiceSelectedRows.length==0){
        status=false;
      }
      return status;
    },
    roleOptions() {
      let data = this.roles;
      return this.convertArrayToObjectByKey(data, "id", "name");
    },
    userOptions() {
      let data = this.users;
      return this.convertArrayToObjectByKey(data, "id", "name");
    },
  },
  data() {
    return {
      id: null,
      role_id:[],
      user_id:null,
      roles:[],
      users:[],
      defaultCurrency: 'TL',
      is_submitting: false,
      selecteMoreOption: false,
      didClickCancel: false,
      accountingDescriptionChecked:false,
      didClickSaved: false,
      invoiceAmount: null,
      invoiceUnitAmount: null,
      invoiceTitle: 'fabric title',
      more_amount_id: null,
      
      waybill_type_id: 3,
      moreAmountOptions: {
        '1': 'Satış Faturası kes',
        '2': 'İade Faturası kes',
        '3': 'Yansıtma Faturası kes',
      },
      today: moment(),
      currentHoveredItem: null,
      waybills: [],
      instructionTypeOptionsRaw: [],
      vatDeducts: [],
      vatExceptions: [],
      vatOptions: [],
      currencyRates: [],
      orderDetails: [],
      // Yansıtma Faturları Başlangıcı
      reflectionInvoiceSuplierCompanySelected:null,
      reflectInvoiceSelectedRows:[],
      createReflectionInvoice:false,
      invoiceReflectTypeSelected:null,
      invoiceReflectTypeOptions:{
        '1':this.$t('general.select_all_line'),
        '2':this.$t('general.select_line')
      },
      reflectionInvoice: [],

      // Yansıtma Faturları Bitiş
      options: {
        instructionTypeOptions: [],
        waybillableOptions: [],
        companyOptions: [],
        entryTypeOptions: {
          '0': this.$t('invoice.enter_manually'),
          '1': this.$t('invoice.scan_qr_code')
        },
        invoiceTypeOptions: [],
        invoiceCategoryOptions: [],
        vatOptions: [],
        vatOptionsRaw: [],
        currencyOptions: [],
        receiveTypeOptions: {},
        waybills: [],
        unitOptions: [],
        vatDeducts: [],
        vatExceptions: []
      },

      transactions: [],

      transaction: {
        id: null,
        vat_rate_id: null,
        waybillable_id: null,
        waybillable_type: null,
        instructable_id: null,
        instructable_type: null,
        order_product_id: null,
        line_code: null,
        line_code_name: null,
        amount: null,
        amount_unit_id: null,
        unit_price: null,
        vat_price: null,
        price: null,
        total_price: null,
        description: null,
        waybillable_ids: [],
        waybillableDetails: [],
        orderOptions: [],
        waybillable: null,
        instructable: null,
        instruction_type_id: null,
        waybill_id: null,
        net_amount: null,
        unit_id: null,
        vat_rate: null,
        exchange_rate: null,
        withholding_share: null,
        withholding_denominator: null,
        product_color_id: null,
        isSubmitted: false,
      },
      instruction_type_id: 1,
      form: {
        is_withholding: '0',

        file_names: ['image'],
        supplier_company_id: null,
        invoice_type_id: null,
        invoice_category_id: null,
        invoice_no: null,
        vat_rate_id: null,
        image: null,
        invoice_date: moment().format(LARAVEL_DATE_FORMAT),
        waybill_ids: [],
        orderOptions: [],
        currency_unit_id: null,
        invoice_receiving_type_id: null,
        exchange_rate: null,
        total_amount_including_taxes: null,
        amount_to_be_paid: null,
        total_discount: null,
        calculated_vat: null,
        total_amount: null,
        withholding_amount: null,
        withholding_amount_calculated_vat: null,
      },

      items: {data: []},
      fields: [
        {
          key: 'is_selected',
          label: this.$t('general.select'),
          sortable: false,
          class: 'text-center text-center border border-top-0 border-left-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'company_name',
          label: this.$t('invoice.company_name'),
          sortable: false,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'order_number',
          label: this.$t('invoice.order_number'),
          sortable: false,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'qr_code_no',
          label: this.$t('invoice.qr_code_no'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'waybill_date',
          label: this.$t('invoice.waybill_date'),
          sortable: true,
          class: 'text-center border border-top-0 max-width-125px font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'invoice_number',
          label: this.$t('invoice.invoice_number'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'invoice_type',
          label: this.$t('invoice.invoice_type'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'invoice_kind',
          label: this.$t('invoice.invoice_kind'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'color',
          label: this.$t('invoice.color'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'size',
          label: this.$t('invoice.size'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'waybill_quantity',
          label: this.$t('invoice.quantity'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'normal_image',
          label: this.$t('invoice.document'),
          sortable: true,
          class: 'text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
      ],
    };
  },
  methods: {
    ...mapMutations({}),
    changeUser(data){
      let user_id = data.newValue
      let invoiceId=this.$route.params.id;
      let payload = {
        url: "api/change-owner-invoice-user",
        
        contents: {
          invoice_id: +invoiceId,
          user_id: +user_id,
        },
      };
      this.$store.dispatch(CREATE_ITEM, payload).then((response)=>{
        if(response.status){
          this.$t("general.successfully_updated")
        }
      });
    },
    changeRole(data){
      let role_id = data.newValue
      let formatedRole = [];
      role_id.forEach((role) => formatedRole.push(+role));
      let invoiceId=this.$route.params.id;
      let payload = {
        url: "api/change-owner-invoice-role",
        contents: {
          invoice_id: +invoiceId,
          role_id: formatedRole,
        }
      };
      this.$store.dispatch(CREATE_ITEM, payload).then((response)=>{
        if(response.status){
          this.$t("general.successfully_updated")
        }
      });
    },
    setUpForEdit() {
      let self = this;
      self.$store.commit(SET_LOADING, true);
      self.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
        url: 'api/invoice/' + self.$route.params.id,
        acceptPromise: true,
      }).then((response) => {
        if (response.status) {
          let responseData = response.data;
          self.id = responseData.id;
          self.today = moment(responseData.created_at, LARAVEL_DATE_FORMAT);
          self.form = responseData;
          self.form.invoice_date = moment(responseData.invoice_date, LARAVEL_DATE_FORMAT);
          self.form.image = responseData.image_link;
          self.form.description = responseData.description ? JSON.parse(responseData.description) : [];
          self.form.logo_waybills = responseData.logo_waybills ? JSON.parse(responseData.logo_waybills).join(", ") : "-";
          self.form.is_withholding = responseData.withholding_code ? 1 : 0;
          self.form.file_names = ['image'];
          self.form.waybill_ids = [];
          self.form.orderOptions = [];
          self.form.invoice_history=responseData.invoice_history;
          if(responseData.invoice_owner_user){
            this.user_id=responseData.invoice_owner_user.user_id;
          }
          if(responseData.invoice_roles && responseData.invoice_roles.length>0){
            this.role_id=responseData.invoice_roles.map((role) => {
              return String(role.role_id);
            });
          }
          if (self.form.invoice_history) {
            self.form.invoice_history.forEach((history, index) => {
            self.form.invoice_history[index]['created_at'] = moment(self.form.invoice_history[index]['created_at']).format(MOMENT_SYSTEM_DATE_FORMAT)
          })
          if(self.form.accounting_description!=null){
            self.accountingDescriptionChecked=true;
          }
        }

          responseData.transactions.forEach((item, index) => {
            let hideLoading = responseData.transactions.length - 1 === index;
            let object = _.clone(this.transaction);
            let newItem = null;
            item.orderOptions = null;
            if (item.waybillable_id) {
              newItem = _.merge(object, item);
            } else {
              newItem = _.merge(object, item);
            }
            self.orderDetails.push(newItem);

            this.getWaybillOrderOptions(item.instruction_type_id, index, newItem, hideLoading);
          });
        } else {
          self.$store.commit(SET_LOADING, false);
          let response = response.data.response;
          if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message')) {
            self.sweetAlertError(response.data.message);
          } else {
            self.sweetAlertError(response.data);
          }
        }
      })
    },
    addNewRow() {
      let transaction = _.cloneDeep(this.transaction);
      this.orderDetails.push(transaction);
    },
    showHistoryInvoiceModal() {
      this.$refs['display-history-invoice-modal'].show()
    },
    closeModal(name = "display-history-invoice-modal") {
      this.$refs[name].hide()
    },
    getWaybills() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/waybills',
        filters: {
          invoiceable: true,
          transaction_type: 1,
          supplier_company_id: this.form.supplier_company_id
        }
      }).then(response => {
        if (response.status) {
          self.waybills = response.data.data;
          self.options.waybills = self.convertArrayToObjectByKey(response.data.data, 'id', 'document_number');
        }
      });
    },
    onSelectProduct(event, key) {
      let selectedItem = this.orderDetails[key].selectedOrderOption;
      if (selectedItem) {
        let selectedItemDetail = selectedItem.detail[event.newValue[0]];
        if (selectedItemDetail) {
          this.orderDetails[key].waybillable_id = selectedItem.waybillable_id;
          this.orderDetails[key].waybillable_type = selectedItem.waybillable_type;
          this.orderDetails[key].instructable_id = selectedItemDetail.instructable_id
          this.orderDetails[key].instructable_type = selectedItemDetail.instructable_type;
          this.orderDetails[key].order_product_id = selectedItemDetail.order_product_id;
          this.orderDetails[key].order_size_and_color_id = selectedItemDetail.order_size_and_color_id;
          this.orderDetails[key].unit_id = selectedItemDetail.amount_unit_id;
        }
      }
    },
    onSelectProductForReflectInvoice(event, key) {
      let selectedItem = this.orderDetails[key].selectedOrderOption;
      if (selectedItem) {
        let selectedItemDetail = selectedItem.detail[event.newValue[0]];
        if (selectedItemDetail) {
          this.orderDetails[key].waybillable_id = selectedItem.waybillable_id;
          this.orderDetails[key].waybillable_type = selectedItem.waybillable_type;
          this.orderDetails[key].instructable_id = selectedItemDetail.instructable_id
          this.orderDetails[key].instructable_type = selectedItemDetail.instructable_type;
          this.orderDetails[key].order_product_id = selectedItemDetail.order_product_id;
          this.orderDetails[key].order_size_and_color_id = selectedItemDetail.order_size_and_color_id;
          this.orderDetails[key].unit_id = selectedItemDetail.amount_unit_id;
        }
      }
    },
    waybillableIdsChanged(event, key) {
      if (event.newValue[0]) {
        this.orderDetails[key].waybillable = null;
        this.orderDetails[key].product_color_id = null;
        this.orderDetails[key].selectedOrderOption = this.orderDetails[key].orderOptions[event.newValue[0]];
        this.orderDetails[key].waybillable = this.orderDetails[key].orderOptions[event.newValue[0]];
      }
    },
    waybillableIdsChangedForReflectInvoice(event, key) {
      if (event.newValue[0]) {
        this.orderDetails[key].waybillable = null;
        this.orderDetails[key].product_color_id = null;
        this.orderDetails[key].selectedOrderOption = this.orderDetails[key].orderOptions[event.newValue[0]];
        this.orderDetails[key].waybillable = this.orderDetails[key].orderOptions[event.newValue[0]];
      }
    },
    instructionTypeChanged(event, key) {
      if (event.newValue[0]) {
        this.orderDetails[key].waybillable = null;
        this.orderDetails[key].product_color_id = null;
        this.orderDetails[key].waybillable_ids = [];
        this.orderDetails[key].selectedInstructionTypeOption = this.instructionTypeOptionsRaw.find(item => +item.id === +event.newValue[0]);
        this.getWaybillOrderOptions(event.newValue[0], key);
      }
    },
    getWaybillOrderOptions(instruction_type_id = null, orderIndex, item = null, hideLoading = false) {
      let instructionTypeId = instruction_type_id ? instruction_type_id : this.instruction_type_id;
      let self = this;
      if (this.form.supplier_company_id != null && instructionTypeId != null) {
        this.$store.dispatch(GET_ITEMS, {
          url: 'api/waybill/waybill-order-option',
          filters: {
            is_invoice: 1,
            instruction_type_id: instructionTypeId,
            supplier_company_id: this.form.supplier_company_id
          },
          acceptPromise: true,
        }).then((result) => {
          if (result.status) {
            let orderOptions = result.data;

            if (!item) {
              item = self.orderDetails[orderIndex];
            }

            let prefix = "O";
            if (item.waybillable_type === "App\\Models\\Sample") {
              prefix = "S";
            }
            item.orderOptions = orderOptions;

            if (item.waybillable_id) {
              let orderOption = prefix + "-" + item.waybillable_id;
              item.waybillable_ids = [prefix + "-" + item.waybillable_id];
              item.waybillable = null;
              item.product_color_id = null;
              item.selectedOrderOption = orderOptions[orderOption];
              item.waybillable = orderOptions[orderOption];
              let selectedItem = item.selectedOrderOption;

              if (selectedItem) {
                item.waybillable_id = selectedItem.waybillable_id;
                item.waybillable_type = selectedItem.waybillable_type;

                let selectedItemDetail = selectedItem.detail[item.instructable_id];

                if (selectedItemDetail) {
                  item.product_color_id = item.instructable_id;
                  item.instructable_id = selectedItemDetail.instructable_id
                  item.instructable_type = selectedItemDetail.instructable_type;
                  item.order_product_id = selectedItemDetail.order_product_id;
                  item.order_size_and_color_id = selectedItemDetail.order_size_and_color_id;
                  item.unit_id = selectedItemDetail.amount_unit_id;
                }
              }
            }

            self.orderDetails[orderIndex] = item;

            if (hideLoading) {
              self.$store.commit(SET_LOADING, false);
            }
          } else {
            self.$store.commit(SET_LOADING, false);
          }
        });
      }
    },
    getWaybillOrderOptionsForReflectInvoice(instruction_type_id = null, orderIndex, item = null, hideLoading = false) {
      let instructionTypeId = instruction_type_id ? instruction_type_id : this.instruction_type_id;
      let self = this;
      if (this.form.supplier_company_id != null && instructionTypeId != null) {
        this.$store.dispatch(GET_ITEMS, {
          url: 'api/waybill/waybill-order-option',
          filters: {
            is_invoice: 1,
            instruction_type_id: instructionTypeId,
            supplier_company_id: this.form.supplier_company_id
          },
          acceptPromise: true,
        }).then((result) => {
          if (result.status) {
            let orderOptions = result.data;

            if (!item) {
              item = self.orderDetails[orderIndex];
            }

            let prefix = "O";
            if (item.waybillable_type === "App\\Models\\Sample") {
              prefix = "S";
            }
            item.orderOptions = orderOptions;

            if (item.waybillable_id) {
              let orderOption = prefix + "-" + item.waybillable_id;
              item.waybillable_ids = [prefix + "-" + item.waybillable_id];
              item.waybillable = null;
              item.product_color_id = null;
              item.selectedOrderOption = orderOptions[orderOption];
              item.waybillable = orderOptions[orderOption];
              let selectedItem = item.selectedOrderOption;

              if (selectedItem) {
                item.waybillable_id = selectedItem.waybillable_id;
                item.waybillable_type = selectedItem.waybillable_type;

                let selectedItemDetail = selectedItem.detail[item.instructable_id];

                if (selectedItemDetail) {
                  item.product_color_id = item.instructable_id;
                  item.instructable_id = selectedItemDetail.instructable_id
                  item.instructable_type = selectedItemDetail.instructable_type;
                  item.order_product_id = selectedItemDetail.order_product_id;
                  item.order_size_and_color_id = selectedItemDetail.order_size_and_color_id;
                  item.unit_id = selectedItemDetail.amount_unit_id;
                }
              }
            }

            self.orderDetails[orderIndex] = item;

            if (hideLoading) {
              self.$store.commit(SET_LOADING, false);
            }
          } else {
            self.$store.commit(SET_LOADING, false);
          }
        });
      }
    },
    submitInvoice() {
      if (!this.isUserGranted('Invoice', ['update', 'create'])) {
        return false;
      }

      if (this.is_submitting) return;
      this.is_submitting = true;
      let invoiceTransactions = this.orderDetails;
      let self = this;
      let transactions = [];

      invoiceTransactions.forEach((transaction, index) => {
        let tmpItem = {
          id: transaction.id,
          waybillable_id: transaction.waybillable_id || null,
          waybillable_type: transaction.waybillable_type || null,
          instructable_id: transaction.instructable_id || null,
          instructable_type: transaction.instructable_type || null,
          order_product_id: transaction.order_product_id || null,
          instruction_type_id: transaction.instruction_type_id || null,
          order_size_and_color_id: transaction.order_size_and_color_id || null,
          line_code: transaction.line_code,
          line_code_name: transaction.line_code_name,
          vat_rate_id: transaction.vat_rate_id,
          amount: transaction.amount,
          amount_unit_id: transaction.amount_unit_id,
          unit_price: transaction.unit_price,
          vat_price: this.transactionVatPrice(index),
          price: this.transactionPrice(index),
          total_price: this.transactionTotalPrice(index),
          description: transaction.description,
        };

        if (transaction.id) {
          tmpItem.id = transaction.id;
        }
        transactions.push(tmpItem);
      })

      this.form.waybills = [];
      this.form.waybills = this.form.waybill_ids.map(id => {
        return {id: id};
      });

      let tempForm = {
        transactions: transactions,
        waybills: this.form.waybills,
        overhead_invoice:this.form.overhead_invoice,
        accounting_description:this.form.accounting_description,
        invoice_no:this.form.invoice_no,
        reflection_invoice:null
      };
      if(this.reflectionInvoice.length>0){
        tempForm.reflection_invoice=this.reflectionInvoice;
      }
      let url = `api/invoice/` + this.id;

      this.sweetAlertLoading();
      this.$store.dispatch(UPDATE_ITEM_BY_ID, {
        url: url,
        contents: tempForm
      }).then(response => {
        self.sweetAlertClose();
        self.is_submitting = false;
        if (response.status) {
          setTimeout(function () {
            self.sweetAlertSuccess(self.$t('invoice.invoice_success_message')).then(() => {
              self.$router.go(-1);
            })
          }, 500);
        } else {
          let result = response.data.response;
          if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        }
      })
    },
    onRowClicked(item, index, event) {
      if (this.form.waybill_ids.includes(item.id)) {
        this.form.waybill_ids.splice(this.form.waybill_ids.indexOf(item.id), 1);
      } else {
        this.form.waybill_ids.push(item.id);
      }
    },
    mouseLeave(e) {
      this.currentHoveredItem = null;
    },
    mouseMove(e) {
      if (this.currentHoveredItem === null) return;
      document.getElementById('detail-pop-up').style.top = (e.pageY + 20) + 'px';
      document.getElementById('detail-pop-up').style.left = (e.pageX - (175 / 2)) + 'px';
    },
    rowHovered(item) {
      this.currentHoveredItem = item;
    },
    onInvoiceUpload(payload) {
      document.getElementById('invoice_image').src = payload.blob;
    },
    clearReturnInvoicesOptions(){
      this.reflectInvoiceSelectedRows=[];
      this.invoiceReflectTypeSelected=null;
      this.reflectionInvoiceSuplierCompanySelected=null;
      this.createReflectionInvoice=false;
    },
    createReflectionInvoices(){
      let transactions=_.cloneDeep(this.orderDetails);
      let form =_.cloneDeep(this.form)
      let newLine={
          supplier_company_id:this.reflectionInvoiceSuplierCompanySelected,
          accounting_description_checked:false,
          accounting_description:null,
          invoice_category_id:7,
          invoice_type_id:1,
          total_amount:null,
          total_amount_including_taxes:null,
          amount_to_be_paid:null,
          calculated_vat:null,
          calculated_vat_converted:null,
          total_amount_converted:null,
          total_discount_converted:null,
          amount_to_be_paid_converted:null,
          total_amount_including_taxes_converted:null,
          exchange_rate:form.exchange_rate,
          total_discount:form.total_discount,
          vat_rates:this.vat_rates,
          currency_unit_id:form.currency_unit_id,
          transactions:[]
        }
        
      if(this.invoiceReflectTypeSelected==1){
        newLine.transactions=[];
        let allTransaction=_.cloneDeep(transactions);
        newLine.transactions=allTransaction;
        this.reflectionInvoice.push(newLine);
        
      }else{
        /* eğer yansıtma faturası kesileceği zaman satırlar seçilmişse 
       seçilen satırların indexleri form transactionslar içerisinden bulunuyor */
        let transactionTmp=[]
        
        this.reflectInvoiceSelectedRows.forEach(function(item){
          newLine.transactions=[];
          let foundTransactions=_.cloneDeep(transactions[item]);
          transactionTmp.push(foundTransactions);
        });

        transactionTmp = _.sortBy(transactionTmp, 'id');
        newLine.transactions=transactionTmp
        this.reflectionInvoice.push(newLine)
      }
      this.clearReturnInvoicesOptions();
    },
    deleteReflectInvoicesItem(index){
      if(this.reflectionInvoice.length>0){
        this.reflectionInvoice.splice(index, 1);
      }
    },
    giveUpReflectionInvoices(){
      this.createReflectionInvoice=false;
      this.reflectionInvoice=[];
      this.reflectInvoiceSelectedRows=[];
      this.invoiceReflectTypeSelected=null;
      this.reflectionInvoiceSuplierCompanySelected=null;
    },
    getRolesData() {
      let payload = {
        url: "api/roles",
        returnType: "stateless",
      };

      this.$store.dispatch(GET_ITEMS, payload).then((response) => {
        if (response.status) {
          this.roles = response.data;
        }
      });
    },
  },
  created() {
    this.$store.commit(RESET_VALUES);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('general.dashboard'), route: "dashboard"},
      {title: this.$t('invoice.invoices'), route: "invoice.index"},
      {title: this.$t('invoice.edit')}
    ]);

    if (!this.isUserGranted('Invoice', ['update', 'create'])) {
      return false;
    }

    this.sweetAlertLoading();

    let self = this, promises = [];
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/supplier-companies`, filters: {all: true}}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/currency`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/invoice-type`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/invoice-category`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/invoice-receive-type`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/instruction-type`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {
      url: 'api/vat-rates', filters: {
        type_id: 4,
      }
    }));

    promises.push(this.$store.dispatch(GET_ITEMS, {url: 'api/units'}));

    promises.push(this.$store.dispatch(GET_ITEMS, {url: 'api/currency-rate'}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: 'api/roles'}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: '/api/users'}));

    Promise.all(promises).then(responses => {
      if (responses[0].status) {
        self.options.companyOptions = self.convertArrayToObjectByKey(responses[0].data, 'id', 'name_for_accounting');
      }
      if (responses[1].status) {
        self.options.currencyOptions = self.convertArrayToObjectByKey(responses[1].data, 'id', 'code');
      }
      if (responses[2].status) {
        self.options.invoiceTypeOptions = self.convertArrayToObjectByKey(responses[2].data, 'id', 'translations.0.name');
      }
      if (responses[3].status) {
        self.options.invoiceCategoryOptions = self.convertArrayToObjectByKey(responses[3].data, 'id', 'translations.0.name');
      }
      if (responses[4].status) {
        self.options.receiveTypeOptions = self.convertArrayToObjectByKey(responses[4].data, 'id', 'translations.0.name');
        self.options.receiveTypeOptions['0'] = self.$t('invoice.receive_type');
      }
      if (responses[5].status) {
        self.options.instructionTypeOptions = self.convertArrayToObjectByKeyList(responses[5].data, 'id', ['translations.0.name','instructionable.code'])
        self.instructionTypeOptionsRaw = responses[5].data;
      }
      if (responses[6].status) {
        self.options.vatOptionsRaw = responses[6].data;
        self.options.vatOptions = self.convertArrayToObjectByKey(responses[6].data, 'id', 'vat.rate');
      }

      if (responses[7].status) {
        self.options.unitOptions = self.convertArrayToObjectByKey(responses[7].data, 'id', 'translations.0.name');
      }

      if (responses[8].status) {
        self.currencyRates = responses[8].data.data;
      }

      if (this.$route.params.id != null) {
        this.setUpForEdit();
      } else {
        let object = _.clone(this.transaction);
        this.orderDetails.push(object);
      }

      if(responses[9].status){
        this.roles = responses[9].data;
      }
      if(responses[10].status){
        this.users = responses[10].data;
      }
    })

    this.sweetAlertClose();
    setTimeout(this.onHitBottom, 500);
  },
  watch: {
    more_amount_id(newValue, oldValue) {
      if (newValue != null)
        this.selecteMoreOption = true;
    },
    'reflectionInvoice': {
      handler: function () {
        
        
        this.reflectionInvoice.forEach((reflectRow,reflectIndex)=>{
          let totalAmount = 0;
          let totalVat = 0;
          let reflectItem=this.reflectionInvoice[reflectIndex];

          reflectRow.transactions.forEach((item, index) => {
            let itemPrice = item.amount * item.unit_price;
            let rate = this.options.vatOptions[item.vat_rate_id];

            totalAmount += itemPrice;
            totalVat += itemPrice * rate / 100;
            
            item.price = item.amount * item.unit_price;
            item.vat_price = item.price * rate / 100;
            item.total_price = item.price + item.vat_price;
            item.vat_rate = rate;

          });

          reflectItem.total_amount = totalAmount;
          reflectItem.total_amount_including_taxes = totalAmount + totalVat;
          reflectItem.amount_to_be_paid = totalAmount + totalVat - reflectItem.total_discount;
          reflectItem.calculated_vat = totalVat;

          if (reflectItem && reflectItem.currency_unit_id !== 160) {
            let exchangeRate = reflectItem.exchange_rate || 1;
            reflectItem.calculated_vat_converted = reflectItem.calculated_vat * exchangeRate;
            reflectItem.total_amount_converted = reflectItem.total_amount * exchangeRate;
            reflectItem.total_discount_converted = reflectItem.total_discount * exchangeRate;
            reflectItem.amount_to_be_paid_converted = reflectItem.amount_to_be_paid * exchangeRate;
            reflectItem.total_amount_including_taxes_converted = reflectItem.total_amount_including_taxes * exchangeRate;
          }
          
        });
        this.vat_rates_reflection;
        //
        
      },
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
.h-50vh {
  height: 50vh !important;
}
</style>
